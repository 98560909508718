<template>
  <div class="find-id-frame">
    <div class="modal-cover" v-show="isShowModal"></div>
    <div class="header">
      <router-link to="/" class="btn-go-home">
        <img src="@/assets/images/IA/icon_home_w.png" alt="icon">
        <p>홈으로 이동</p>
      </router-link>
    </div>
    <div class="main-container">
      <img src="@/assets/images/IA/img_primary_logo.png" alt="logo">
      <div class="find-id-form">
        <h1>비밀번호 찾기</h1>
        <p class="guide-text">
          등록된 아이디(이메일)로 비밀번호 재설정 링크를 발송해드립니다.<br>
          정보 확인이 불가할시 jjkorom@gmail.com으로 <br>연락주십시오.
        </p>
        <div class="label-box">
          <label>
            아이디(이메일)
          </label>
          <input type="text" placeholder="이메일 입력" class="custom-input-box" v-model="email">
        </div>
        <button class="custom-btn-box" @click="resetPassword">비밀번호 재설정 링크 발송</button>
      </div>
    </div>
    <Progress :isProgress="isProgress"/>
    <RePasswordEmailDialog @callBackModal="callBackModal" v-if="isShowModal"/>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";
import Progress from "@/components/Progress";
import {firebaseError} from "@/lib/firebaseError";
import RePasswordEmailDialog from "@/views/common/RePasswordEmailDialog";

export default {
  name: "TherapistFindPassword",
  components: {RePasswordEmailDialog, Progress},
  data() {
    return {
      fbCollection: 'therapists',
      isProgress: false,
      isShowModal: false,
      email: '',
    }
  },
  methods: {
    async resetPassword() {
      const self = this;
      if (self.email.trim().length === 0) {
        alert('이메일을 입력하세요.');
        return
      }
      self.isProgress = true;
      await firebase.auth().sendPasswordResetEmail(self.email)
          .then(async () => {
            self.isShowModal = true;
            self.isProgress = false;
          })
          .catch((error) => {
            // 실패 후 실행할 코드
            firebaseError(error);
            self.isProgress = false;
          });

    },
    callBackModal: function (index) {
      const self = this;
      if (index === 0) {
        self.isShowModal = false
        self.$router.go(-1)
      }
    },
  }
}
</script>

<style scoped>

.custom-input-box {
  font-size: 16px;
}

.guide-text {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #0069CA;
  margin: 17px 0 40px;
}

strong {
  margin-left: 13px;
}

.find-id-frame {
  background-color: #A0B0C6;
}

.header {
  height: 80px;
  display: flex;
  flex-direction: row;
}

.btn-go-home {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.btn-go-home img {
  width: 20px;
  height: 20px;
}

.btn-go-home p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  margin: 0 15px 0 5px;
}

.main-container {
  height: 688px;
  text-align: center;
}


.main-container img {
  width: 193px;
  height: 41px;
  margin-top: 64px;
  margin-bottom: 30px;
}

.find-id-form {
  margin: 0 auto;
  width: 418px;
  height: 387px;
  background: #FFFFFF;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
}

.find-id-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
}

.find-id-form h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 40px;
}

.find-id-form h4 strong {
  color: #0069CA;
  cursor: pointer;
  margin-left: 8px;
}

.find-id-form label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
  text-align: left !important;
}

.label-box {
  text-align: left;
  margin-bottom: 20px;
}

.btn-find {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
}

.btn-find:first-child {
  margin-right: 3px;
}

.btn-find:last-child {
  margin-left: 3px;
}

.custom-btn-box {
  margin-top: auto;
}
</style>


