<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        비밀번호 재설정 링크가 발송되었습니다.
      </h5>
    </div>
    <div class="modal-center">
      <p>입력하신 메일로 발송된 재설정 링크를 확인해주세요.</p>
    </div>
    <div class="modal-footer">
      <button class="btn-confirm" @click="btnConfirm">로그인 하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RePasswordEmailDialog",
  methods: {
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal', 0)
    },
  }
}
</script>

<style scoped>
p {
  font-size: 14px !important;
  margin-top: 8px;
}

button, strong {
  font-size: 16px !important;
}

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  margin: 0;
}

.modal-center {
  padding: 30px 60px;
  background: #F8F8FA;
}

.modal-center h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #0069CA;
}

.modal-center p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #000000;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.btn-close {
  width: 84px;
  height: 40px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: rgba(24, 24, 25, 0.9);

}

.guide {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #E14F4E;
  margin-bottom: 20px;
  margin-top: 8px;
}

.btn-confirm {
  width: 109px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
}


</style>
